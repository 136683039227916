import React from 'react';
import { makeStyles, Grid, Box, Typography, Container } from '@material-ui/core';

//IMAGENS
import imgEmpresa from '../assets/images/imgEmpresa.webp';
import bgEmpresa from '../assets/images/bgEmpresa.webp';
//ICONS
import AcUnitIcon from '@material-ui/icons/AcUnit';

const useStyles = makeStyles((theme) => ({
  bgEmpresa: {
      backgroundSize: '50% 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top 0 right 0',
      backgroundImage: `url(${bgEmpresa})`,
      [theme.breakpoints.down('sm')]: {
        backgroundSize: '100% 50%',
        backgroundPosition: 'bottom'
  }},
  img: {
    borderRadius: '.5rem',
    width: '100%',
  },
  dark: {
    color: theme.palette.common.black,
  },
  dark2: {
    color: '#636360',
  },
  icon: {
    fontSize: '2.2rem',
    color: theme.palette.info.main,
  }
}));

export default function SobreEmpresa() {
  const classes = useStyles();
  return (
    <Grid className={classes.bgEmpresa}>
      <Container id="quem-somos">
        <Box display="flex" flexDirection="row" flexWrap="wrap" py={10} px={0}>
          <Grid item sm={12} md={4}>
            <Box p={3} mb={5}>
              <AcUnitIcon className={classes.icon} />
              <Typography variant='h4' component='h3' className={classes.dark} >
                Sobre Empresa
              </Typography>
              <Box mb={{xs: 5}} mt={{xs: 7}}>
              <Typography variant='body2' component='p' className={classes.dark2}>
                A Maia Projetos é uma empresa voltada para desenvolvimento e consultorias em projetos de ar condicionado e exaustão mecânica de cozinha industrial. Com a experiência em projetos nos grandes players de mercado e com atuação nacional, o objetivo da empresa é entregar um atendimento personalizado em todas as fases do projeto (Gerenciamento, Implantação, operação e encerramento).
              </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={12} md={6}>
            <Box mt={10} p={3} pl={{ sm: 0, md: 20 }}>
              <img className={classes.img} src={imgEmpresa} alt='Empresa' />
            </Box>
          </Grid>
        </Box>
      </Container>
    </Grid>
  )
}