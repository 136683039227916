import React from 'react';
import { Button, makeStyles, Typography, Container, Grid, Box } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
//IMAGES
import bgHeader580 from '../assets/images/bgHeader580.webp';
import bgHeader940 from '../assets/images/bgHeader940.webp';
import bgHeader1440 from '../assets/images/bgHeader.webp';
import hvac from '../assets/images/hvac.webp';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center top',
    backgroundColor: 'transparent',
  },
  HeaderContent: {
    position: 'absolute',
    top: '120px',
    [theme.breakpoints.up('md')]: {
      top:'150px',
    },
    [theme.breakpoints.down('xs')]: {
      top:'40px',
    }
  },
  bgHeader: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height:'750px',
      objectFit:'cover',
    }
  },
  h1Title: {
    lineHeight: '2.5rem',
    fontWeight: '400',
  },
  button: {
    backgroundColor: 'secondary',
    padding: '0.75rem 4rem',
    marginTop: '3rem',
    color: 'white',
    boxShadow: 'none',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    fontWeight: 'Bold',
  },
}));

export default function Header(props) {
  const classes = useStyles();
  return (
    <Grid className={classes.header}>
      <Box display={{ xs: 'block', sm: 'none', md: 'none' }}>
        <img src={bgHeader580} alt='Logo' className={classes.bgHeader} />
      </Box>
      <Box display={{ xs: 'none', sm: 'block', md: 'none' }}>
        <img src={bgHeader940} alt='Logo' className={classes.bgHeader} />
      </Box>
      <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
        <img src={bgHeader1440} alt='Logo' className={classes.bgHeader} />
      </Box>
      <Container>
        <Box display='flex' justifyContent='space-between' alignItems='flex-end' flexWrap='wrap' className={classes.HeaderContent}>
          <Grid item sm={10} md={7} lg={6}>
            <Box mt={12} mb={{xs:10}}pt={12} px={4}>
              <Typography variant='h3' component='h1' color="primary" className={classes.h1Title}>
                Consultoria e desenvolvimento de projetos de ar condicionado
              </Typography>
              <Typography variant='body1' component='p' color="primary">
                Agências Bancárias, Hotéis, Escritórios e Edifícios Corporativos, Escolas, Hospitais, Prédios Históricos, Laboratórios e Lojas.
              </Typography>
              <Button className={classes.button} variant="contained" color="secondary" href='https://api.whatsapp.com/send?phone=5521996416096&text=Ol%C3%A1,%20Maia%20Projetos' target='_blank'>
                Entre em contato
                <ArrowRightAltIcon />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box display='flex' alignItems='flex-end' justifyContent='center' p={{xs:'10', md:'0' }}>
              <img src={hvac} alt='HVAC' />
            </Box>
          </Grid>
        </Box>
      </Container>
    </Grid>
  );
}
