import React from 'react';
import Header from './Header';
import TopBarHome from './TopBarHome';
import SobreEmpresa from './SobreEmpresa';
import NossosServicos from './NossosServicos';
import AreasAtuacao from './AreasAtuacao';
import Clientes from './Clientes';
import Beneficios from './Beneficios';
import SliderProjetos from './SliderProjetos';
import Footer from './Footer';
import { makeStyles, Box, Typography, Grid, Container, Button } from '@material-ui/core';
// Imagens
import bgEmail from '../assets/images/bgEmail.webp';
//Icons
import AcUnitIcon from '@material-ui/icons/AcUnit';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import AssignmentIcon from '@material-ui/icons/Assignment';
import SettingsIcon from '@material-ui/icons/Settings';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';

const useStyles = makeStyles((theme) => ({
   cardServicos: {
      background: 'linear-gradient(180deg, rgba(236, 239, 247, 0) 0%, rgba(236, 239, 247, 0.5) 100%)',
      height: '140px',
      borderRadius: '.5rem',
      padding: '1.3rem',
      margin: '1em',
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
   },
   cardServicosBoxIcon: {
      backgroundColor: '#E4F5FF',
      borderRadius: '100%',
      padding: '1rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '.8rem',
   },
   cardServicosIcon: {
      color: '#007EC7',
      fontSize: '2rem',
   },
   dark: {
      color: theme.palette.common.black,
      fontWeight: '400',
   },
   white: {
      color: theme.palette.primary.main,
   },
   fontBold: {
      fontWeight: 'bold',
   },
   marginRight: {
      marginRight: '20px',
   },
   marginBottom: {
      marginBottom: '0px',
   },
   img: {
      width: '100%',
   },
   bgEmail: {
      backgroundImage: `url(${bgEmail})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
   },
   inputEmail: {
      backgroundColor: '##ECEFF7',
      fontSize: '0.8rem',
      color: '#636360',
      fontFamily: 'Roboto',
      border: '0',
      borderRadius: '4px',
      padding: '1rem .5rem',
      width: '60%',
      height: '48px',
      [theme.breakpoints.down('sm')]: {
         width: '100%',
      },
   },
   button: {
      backgroundColor: 'secondary',
      padding: '0.75rem 4rem',
      color: 'white',
      boxShadow: 'none',
      borderRadius: '4px',
      fontFamily: 'Roboto',
      fontWeight: 'Bold',
      margin: '0 .5rem',
      width: '35%',
      height: '48px',
      [theme.breakpoints.down('sm')]: {
         width: '100%',
         margin: 0,
         marginTop: '1rem',
      },
   },
   mNone: {
      marginBottom: '0',
   },
   cardContatos: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      height: '150px',
      borderRadius: '8px',
      padding: '2.5rem 1.5rem',
      color: '#1F1F1F',
      width: '100%',
      textDecoration: 'none',
      [theme.breakpoints.down('sm')]: {
         padding: '.5rem',
      },
   },
   bgLink1: {
      backgroundColor: '#FFE6B8',
   },
   bgLink2: {
      backgroundColor: '#E4F5FF',
      [theme.breakpoints.down('sm')]: {
         marginTop: '1rem',
      },
   },
   bgIcon: {
      borderRadius: '50px',
      backgroundColor: 'white',
      display: 'flex',
      padding: '.8rem',
      [theme.breakpoints.down('sm')]: {
         marginBottom: '1rem',
      }
   },
   orangeIcon: {
      color: '#F39200',
   },
   blueIcon: {
      color: '#007EC7',
   },
   lineBorder: {
      borderBottom: '1px solid #ECE8E8',
   },
   icon: {
      fontSize: '2.2rem',
      color: theme.palette.info.main,
      marginRight: '10px',
   }
}));


// }));

export default function Home() {
   const classes = useStyles();
   return (
      <div>
         <TopBarHome />
         <Header />
         <Container>
            <Box mt={12} pl={4} textAlign={{sm: 'left', md: 'center'}}>
               <AcUnitIcon className={classes.icon} />
               <Typography variant='h4' component='h3' className={`${classes.dark} ${classes.marginBottom}`}>
                  O que fazemos
               </Typography>
            </Box>
            <Box display="flex" flexWrap="wrap" flexDirection="row" px={{ xs: 4, md: 0 }} pb={20}>
               <Grid xs={12} md={4}>
                  <Box className={classes.cardServicos}>
                     <Box className={classes.cardServicosBoxIcon}>
                        <CardTravelIcon className={classes.cardServicosIcon} />
                     </Box>
                     <Typography variant='body1' className={`${classes.fontBold} ${classes.dark}`}>Consultoria técnica, análise de projetos e elaboração de relatórios.</Typography>
                  </Box>
               </Grid>
               <Grid xs={12} md={4}>
                  <Box className={classes.cardServicos}>
                     <Box className={classes.cardServicosBoxIcon}>
                        <FindReplaceIcon className={classes.cardServicosIcon} />
                     </Box>
                     <Typography variant='body1' className={`${classes.fontBold} ${classes.dark}`}>Fiscalização de obras</Typography>
                  </Box>
               </Grid>
               <Grid xs={12} md={4}>
                  <Box className={classes.cardServicos}>
                     <Box className={classes.cardServicosBoxIcon}>
                        <EmojiObjectsIcon className={classes.cardServicosIcon} />
                     </Box>
                     <Typography variant='body1' className={`${classes.fontBold} ${classes.dark}`}>Desenvolvimento de projetos e modelagem em BIM.</Typography>
                  </Box>
               </Grid>
               <Grid xs={12} md={4}>
                  <Box className={classes.cardServicos}>
                     <Box className={classes.cardServicosBoxIcon}>
                        <AssignmentIcon className={classes.cardServicosIcon} />
                     </Box>
                     <Typography variant='body1' className={`${classes.fontBold} ${classes.marginRight} ${classes.dark}`}>Aprovação de projetos junto ao GEM
                        <span className={classes.dark}>(Gerência de engenharia mecânica).</span></Typography>
                  </Box>
               </Grid>
               <Grid xs={12} md={4}>
                  <Box className={classes.cardServicos}>
                     <Box className={classes.cardServicosBoxIcon}>
                        <SettingsIcon className={classes.cardServicosIcon} />
                     </Box>
                     <Typography variant='body1' className={`${classes.fontBold} ${classes.marginRight} ${classes.dark}`}>TAB
                     <span className={classes.dark}>(Testes e balanceamento de sistemas de HVAC).</span></Typography>
                  </Box>
               </Grid>
               <Grid sm={12} md={4}>
                  <Box className={classes.cardServicos}>
                     <Box className={classes.cardServicosBoxIcon}>
                        <ViewQuiltIcon className={classes.cardServicosIcon} />
                     </Box>
                     <Typography variant='body1' className={`${classes.fontBold} ${classes.dark}`}>Projetos de exaustão mecânica para cozinhas industriais.</Typography>
                  </Box>
               </Grid>
            </Box>
         </Container>
         <Beneficios />
         <SobreEmpresa />
         <NossosServicos />
         <SliderProjetos />
         <AreasAtuacao />
         <Grid className={classes.bgEmail} p={12}>
            <Container>
               <Box display='flex' alignItems='center' flexWrap='wrap' justifyContent='space-between' px={{ xs: 4, md: 0 }} py={20}>
                  <Grid xs={12} md={2}>
                     <Typography variant='h4' component='h3' className={classes.white}>
                        Deixe seu email!
                     </Typography>
                  </Grid>
                  <Grid xs={12} md={4}>
                     <Typography variant='body2' component='p' className={classes.mNone}>
                        A Maia Projetos tem soluções completas e personalizadas de acordo com as necessidades da sua empresa. Entraremos em contato o mais breve!
                     </Typography>
                  </Grid>
                  <Grid xs={12} md={5}>
                     <Box pt={{ xs: 8, md: 0 }}>
                        <form className={classes.root} noValidate autoComplete="off">
                           <input type="email" name="name" className={classes.inputEmail} />
                           <Button className={classes.button} variant="contained" color="secondary" href="#">
                              Enviar
                              <ArrowRightAltIcon />
                           </Button>
                        </form>
                     </Box>
                  </Grid>
               </Box>
            </Container>
         </Grid>
         <Clientes />
         <Container id="contatos">
            <Box className={classes.lineBorder} display='flex' justifyContent={{ xs: 'center', md: 'space-around' }} alignItems='center' componente='a' flexWrap='wrap' py={20}>
               <Grid item xs={12} md={6}>
                  <Box p={{sm:'2', md: 5}}>
                     <a className={`${classes.cardContatos} ${classes.bgLink1}`} href='https://api.whatsapp.com/send?phone=5521996416096&text=Ol%C3%A1,%20Maia%20Projetos' target='_blank' rel="noreferrer">
                        <Box display='flex' justifyContent='space-around' alignItems='center' flexWrap='wrap'>
                           <Box className={`${classes.bgIcon} ${classes.orangeIcon}`} mx={5} mb={{sm: 10}}>
                              <PhoneIcon />
                           </Box>
                           <Box display={{ xs: 'none', md: 'block' }} mx={5}>
                              <Typography variant='body2' className={classes.dark}>
                                 Whatsapp
                              </Typography>
                           </Box>
                           <Box mx={5}>
                              <Typography variant='h5' component='p' className={classes.fontBold}>
                                 +55 (21) 99641-6096
                              </Typography>
                           </Box>
                        </Box>
                     </a>
                  </Box>
               </Grid>
               <Grid item xs={12} md={6}>
                  <Box p={{sm:'2', md: 5}}>
                     <a className={`${classes.cardContatos} ${classes.bgLink2}`} href="mailto:vinicius@maiaprojetos.eng.br">
                        <Box display='flex' justifyContent={{ xs: 'center', md: 'space-around' }} alignItems='center' flexWrap='wrap'>
                           <Box className={`${classes.bgIcon} ${classes.blueIcon}`} mx={5}>
                              <MailIcon />
                           </Box>
                           <Box display={{ xs: 'none', md: 'block' }} mx={5}>
                              <Typography variant='body2' className={classes.dark}>
                                 Email
                              </Typography>
                           </Box>
                           <Box mx={5}>
                              <Typography variant='h5' component='p' className={classes.fontBold}>
                                 vinicius@maiaprojetos.eng.br
                              </Typography>
                           </Box>
                        </Box>
                     </a>
                  </Box>
               </Grid>
            </Box>
         </Container>
         {/*<Associacoes /> */}
         <Footer />
      </div>
   );
}