import React, { useState } from 'react';
import { makeStyles, AppBar, Toolbar, Container, IconButton, Grid, Box } from '@material-ui/core';
import TopBarMobile from './TopBarMobile';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import { Link, animateScroll as scroll } from "react-scroll"; //SMOOTH SCROLL
//ICONS
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
//IMAGES
import Logo from '../assets/images/logo-topo.webp';


const useStyles = makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    padding: '1rem 0',
  },
  appBarColor: {
    backgroundColor: 'rgba(0,0,0,0.7)',
  },
  grow: {
    flexGrow: 1,
  },
  buttonOutline: {
    marginRight: theme.spacing(8),
    padding: '0.5rem 1.5rem',
    border: '1px solid white',
  },
  buttonLabel: {
    color: 'white',
    textTransform: 'none',
    fontWeight: 'bold',
  },
  slide: {
    backgroundColor: '#000',
  },
  menuItem: {
    padding: '.5rem 1.5rem',
    fontSize: '1rem',
    lineHeight: '2rem',
    fontFamily: 'Roboto, sans-serif',
    color: theme.palette.primary.main,
    transitionDuration: '400ms',
    textDecoration: 'none',
    '&:focus, &:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: 'rgba(0,0,0,0.7)',
      '&:focus, &:hover': {
        color: theme.palette.secondary.main,
        textDecoration: 'none',
      },
    },
  },
  icons: {
    //paddingRight: theme.spacing(0),
    color: 'white',
    fontSize: '1rem',
    paddingLeft: '.6rem',
    paddingRight: '.6rem',
    [theme.breakpoints.down('sm')]: {
      color: 'rgba(0,0,0,0.6)',
    },
  }
}));

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function Header(props) {
  const classes = useStyles();

  const [appbar, setAppbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 175) {
      setAppbar(true);
    } else {
      setAppbar(false);
    }
  };
  window.addEventListener('scroll', changeBackground);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <React.Fragment>
      <HideOnScroll {...props}>
        <AppBar color='inherit' className={`${classes.appBar} ${appbar ? classes.appBarColor : ''}`}>
          <Toolbar>
            <Container>
              <Grid container justifyContent='space-between' alignItems='space-between'>
                <Grid item xs={6} md={4}>
                  <a href='/'><img onClick={scrollToTop} src={Logo} alt='Logo' /></a>
                </Grid>
                <Grid item md={8}>
                  <Box display={{ xs: 'none', sm: 'none', md: 'flex' }} justifyContent='flex-end' flexWrap='nowrap'>
                    <Grid md={8}>
                      <Box component="Box" display="flex" flexDirection="row" justifyContent="flex-end" flexWrap="nowrap" >
                        <a className={classes.menuItem} href='/' onClick={scrollToTop}>Home</a>
                        <Link className={classes.menuItem} href='/#quem-somos' to="quem-somos" smooth={true} spy={true} duration={600}>Quem Somos</Link>
                        <Link className={classes.menuItem} href='/#servicos' to="servicos" smooth={true} spy={true} duration={700}>Serviços</Link>
                        <Link className={classes.menuItem} href='/#contatos' to="contatos" smooth={true} spy={true} duration={900}>Contatos</Link>
                        <a className={classes.menuItem} href='/blog'>Blog</a>
                      </Box>
                    </Grid>
                    <Grid md={2}>
                      <Box display="flex" flexDirection="row" justifyContent="flex-end" flexWrap="nowrap" >
                        <IconButton href='https://www.instagram.com/consultoria.maia' target='_blank' className={classes.icons}>
                          <InstagramIcon />
                        </IconButton>
                        <IconButton href='https://www.linkedin.com/company/maia-consultoria-e-projetos' target='_blank' className={classes.icons}>
                          <LinkedInIcon />
                        </IconButton>
                        <IconButton href='https://api.whatsapp.com/send?phone=5521996416096&text=Ol%C3%A1,%20Maia%20Projetos' target='_blank' className={classes.icons}>
                          <WhatsAppIcon />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Box>
                  <Box display={{ xs: 'flex', sm: 'flex', md: 'none' }} justifyContent='center' alignItems='center'>
                    <TopBarMobile />
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </React.Fragment>
  );
}

export default Header;