import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './Home';
import Blog from './Blog';
import SinglePost from './SinglePost';

const Rotas = () => (
   <BrowserRouter>
      <Switch>
         <Route exact path='/' component={Home} />
         <Route exact path='/blog' component={Blog} />
         <Route path='/blog/:slug' component={SinglePost} />
      </Switch>
   </BrowserRouter>
);

export default Rotas;