import React from 'react';
import { makeStyles, Box, Grid, Container, Typography } from '@material-ui/core';
import { Link, animateScroll as scroll } from "react-scroll"; //SMOOTH SCROLL
//IMAGES
import logoWhite from '../assets/images/logoWhite.webp';
//ICONS
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';



const useStyles = makeStyles((theme) => ({
  bgColor: {
    background: 'linear-gradient(145.33deg, #000000 37%, #007EC7 167.1%)',
    color: '#ECEFF6',
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontFamily: 'Roboto, sans-serif',
    color: theme.palette.primary.main,
    marginBottom: '.2rem',
    textDecoration: ' none',
    '&:focus, &:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
  icon: {
    fontSize: '1.1rem',
    color: '#007EC7',
  },
  iconWpp: {
    marginLeft: '.5rem',
  },
  iconSocial: {
    marginRight: '.5rem',
  },
  footerBottom: {
    padding: '2rem 0',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    borderTop: '1px solid rgba(236, 239, 247, 0.1)',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  img: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1.2rem',
      display: 'block',
      margin: '0 auto',
    },
  },
}));

export default function Footer() {
  const classes = useStyles();

  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <Grid className={classes.bgColor}>
      <Container>
        <Box display='flex' textAlign={{ xs: 'center' }} flexWrap='wrap' py={15} px={{ xs: 4, md: 0 }}>
          <Grid xs={12} sm={12} md={3} direction='row' justify='center'>
            <Box display='flex' justifyContent={{xs:'center'}} mb={5}>
              <Link href='/'>
                <img className={classes.img} src={logoWhite} alt='Logo' onClick={scrollToTop} />
              </Link>
            </Box>
          </Grid>
          <Grid xs={12} sm={4} md={3}>
            <Typography variant='body1' component='h5'>
              Mapa do Site
            </Typography>
            <Box display='flex' flexDirection='Column' alignItems={{ xs: 'center' }} component='nav' my={5}>
              <Link className={classes.menuItem} href='/' onClick={scrollToTop}>Home</Link>
              <Link className={classes.menuItem} href='#quem-somos' to="quem-somos" smooth={true} spy={true} duration={600}>Quem Somos</Link>
              <Link className={classes.menuItem} href='#servicos' to="servicos" smooth={true} spy={true} duration={700}>Serviços</Link>
              <Link className={classes.menuItem} href='#contatos' to="contatos" smooth={true} spy={true} duration={900}>Contatos</Link>
            </Box>
          </Grid>
          <Grid xs={12} sm={4} md={3}>
            <Typography variant='body1' component='h5'>
              Contatos
            </Typography>
            <Box display='flex' flexDirection='Column' alignItems={{ xs: 'center' }} my={5}>
              <a className={classes.menuItem} href='https://api.whatsapp.com/send?phone=5521996416096&text=Ol%C3%A1,%20Maia%20Projetos'>+55 21 99641-6096 <WhatsAppIcon className={`${classes.icon} ${classes.iconWpp}`} /></a>
              <Typography variant='body2'>
                vinicius@maiaprojetos.eng.br<br />
                comercial@maiaprojetos.eng.br
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} sm={4} md={3}>
            <Typography variant='body1' component='h5'>
              Contatos
            </Typography>
            <Box display='flex' flexDirection='Column' alignItems={{ xs: 'center' }} mt={5}>
              <a className={classes.menuItem} href='https://www.linkedin.com/company/maia-consultoria-e-projetos' target='_blank' rel="noreferrer"><LinkedInIcon className={`${classes.icon} ${classes.iconSocial}`} />Linkedin</a>
              <a className={classes.menuItem} href='https://www.instagram.com/consultoria.maia' target='_blank' rel="noreferrer"><InstagramIcon className={`${classes.icon} ${classes.iconSocial}`} />Instagram</a>
            </Box>
          </Grid>
        </Box>
        <Box className={classes.footerBottom} px={{ xs: 4, md: 0 }}>
          <Grid sm={12} md={6}>
            <Typography variant='body2' >
              © 2021 Maia Projetos - Todos os direitos reservados
            </Typography>
          </Grid>
          <Grid xs={12} sm={12} md={3}>
            <Box display='flex' justifyContent='center' textAlign='center' alignItems='center'>
              <p variant='body2'>
                Desenvolvido por:
              </p>
              <a className={classes.menuItem} href='https://cwa.digital' target='_blank' rel="noreferrer">CWA Digital</a>
            </Box>
          </Grid>
        </Box>
      </Container>
    </Grid>
  )
}
