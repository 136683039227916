import React, { Component } from 'react';
import axios from 'axios';
import { withStyles, Grid, Box, Typography, Container } from '@material-ui/core';
import Moment from 'react-moment';
import TopBar from './TopBar';
import Footer from './Footer';

//ICONS
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = theme => ({
  appBarColor: {
    backgroundColor: 'rgba(0,0,0,0.7)',
    height: '95px',
  },
  dark: {
    color: theme.palette.common.black,
    fontWeight: '400',
  },
  titleBlog: {
    lineHeight: '32px',
  },
});

class SinglePost extends Component {

  constructor(props) {
    super(props);
    this.state = {
      post: {},
    };
    this.createMarkup = this.createMarkup.bind();
  }

  componentDidMount() {
    const slug = this.props.match.params.slug;
    axios
      .get(`https://public-api.wordpress.com/wp/v2/sites/maiaprojetosdear.wordpress.com/posts?slug=${slug}`)
      .then(post => {
        this.setState({
          post: post.data[0],
        });
      });
  }

  createMarkup(html) {
    return { __html: html };
  }

  render() {
    const { classes } = this.props;
    let build;
    if (this.state.post.title) {
      build = (
        <div>

          <div className={classes.appBarColor}>
            <TopBar />
          </div>
          
          <Container>
            <Box display='flex' justifyContent='center' alignItems='center' my={15}>
              <Grid item lg={8}>
                <Box mb={4}>
                  <Typography 
                    variant='h2' 
                    component='h1' 
                    className={`${classes.dark} ${classes.marginBottom}`}
                    dangerouslySetInnerHTML={{
                      __html: this.state.post.title.rendered
                    }}
                  />
                </Box>
                <Typography variant='body2' align='justify' 
                className={`${classes.dark} ${classes.marginBottom}`} gutterBottom='true'
                dangerouslySetInnerHTML={this.createMarkup(
                  this.state.post.excerpt.rendered
                )}
                >
                </Typography>
                <Box display='flex' justifyContent='space-between' alignItems='center' mt={7} className={classes.lineBorder} pb={5}>
                  <Grid item lg={5}>
                    <Typography variant='body1' align='justify' className={classes.dark}>
                      {/* Por {this.state.post.author} */}
                      Por Comunicação
                    </Typography>
                    <Moment date={this.state.post.date} format="DD/MM/YYYY" variant='body2' className={classes.dark} />
                  </Grid>
                  <Grid item lg={5}>
                    <Box display='flex' justifyContent='flex-end'>
                      <WhatsAppIcon className={classes.iconShare} />
                      <LinkedInIcon className={classes.iconShare} />
                      <InstagramIcon className={classes.iconShare} />
                    </Box>
                  </Grid>
                </Box>
                <Box pt={10}>
                  <img 
                    src={this.state.post.jetpack_featured_media_url} 
                    alt={this.state.post.title.rendered} 
                    className={classes.imageBlog} 
                    width='100%' 
                  />
                </Box>
                <Box py={6} display='flex' justifyContent='center' alignItems='center'>
                  <Grid item lg={10}>
                    <Typography
                    variant='body1' className={`${classes.dark}`}
                    gutterBottom='true'
                    dangerouslySetInnerHTML={this.createMarkup(
                      this.state.post.content.rendered
                    )} 
                    />
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </Container>

          <Footer />

      </div>
      );
    } else {
      build = <div />;
    }
    return build;
  }
}

export default withStyles(useStyles)(SinglePost);