import React from 'react';
import { ThemeProvider, createTheme, makeStyles } from '@material-ui/core';
import Routes from './components/Routes';

const useStyles = makeStyles((theme) => ({
  bgColor: {
    backgroundColor: 'white',
  },
}));

const theme = createTheme ({
  root: {
    height: '100vh',
    backgroundColor: 'white',
  },
  spacing: 4,
  palette: {
    common: {
      black: '#000',
      white: '#fff'
    },
    primary: {
      main: '#ECEFF6',
      dark: '#ECEFF7',
      dark2: '#636360',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#F39200',
    },
    info: {
      main: '#007EC7',
    },
    grey: {
      main: '#ECEFF7',
    },
  },
  typography: {
    fontFamily: [
      'Open Sans',
    ].join(','),
    h4: {
      fontSize: '1.5rem',
      lineHeight: '1.65rem',
      marginBottom: '1rem',
      fontFamily: 'Archivo Black , sans-serif',
    },
    h6: {
      fontSize: '1rem',
      lineHeight: '1.375rem',
      fontFamily: 'Archivo Black , sans-serif',
      fontWeight: '700',
    },
    body1: {
      color: 'white',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontFamily: 'Roboto',
    },
    body2: {
      color: 'white',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontFamily: 'Roboto',
    },
  },
});

theme.typography.h1 = {
  fontSize: '4.375rem',
  lineHeight: '4.063rem',
  marginBottom: '2rem',
  fontFamily: 'Archivo Black , sans-serif',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
    lineHeight: '3rem',
  },
};
theme.typography.h2 = {
  fontSize: '3rem',
  lineHeight: '3.5rem',
  marginBottom: '2rem',
  fontFamily: 'Archivo Black , sans-serif',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
  },
};
theme.typography.h3 = {
  fontSize: '2.5rem',
  lineHeight: '3rem',
  marginBottom: '2rem',
  fontFamily: 'Archivo Black , sans-serif',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
    lineHeight: '2.5rem',
    marginBottom: '0rem',
  },
};
theme.typography.h4 = {
  fontSize: '2rem',
  lineHeight: '3rem',
  marginBottom: '2rem',
  fontFamily: 'Archivo Black , sans-serif',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.9rem',
    lineHeight: '2.5rem',
    marginBottom: '0rem',
  },
};
theme.typography.h5 = {
  fontSize: '1.25rem',
  lineHeight: '1.75rem',
  fontFamily: 'Archivo Black , sans-serif',
  [theme.breakpoints.down('sm')]: {
    lineHeight: '1.75rem',
  },
};
theme.typography.body1 = {
  color: 'white',
  fontSize: '1.15rem',
  lineHeight: '1.5rem',
  fontFamily: 'Roboto',
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
};


function App() {

  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <Routes />
      </div>
    </ThemeProvider>
  );

}

export default App;
