import React from 'react';
import { makeStyles, Grid, Box, Container, Typography } from '@material-ui/core';
//IMAGES
import mapa from '../assets/images/map.webp';
import bgAtuacao from '../assets/images/bgAtuacao.webp';
//ICONS
import AcUnitIcon from '@material-ui/icons/AcUnit';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
  img: {
    width: '100%',
  },
  dark: {
    color: theme.palette.common.black,
  },
  dark2: {
    color: '#636360',
  },
  fontBold: {
    fontWeight: 'bold',
  },
  icon: {
    fontSize: '1.8rem',
    color: '#90D7FF',
    marginRight: '1rem',
  },
  bgAtuacao: {
    backgroundSize: 'auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom 100px right 0',
    backgroundImage: `url(${bgAtuacao})`,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'none',
    }
  },
}));


export default function AreasAtuacao() {
  const classes = useStyles();
  return (
    <Grid className={classes.bgAtuacao} >
      <Container >
        <Box display="flex" flexDirection={{ xs: 'column-reverse', md: 'row' }} justifyContent="space-between" flexWrap="wrap" p={20} px={0}>
          <Grid sm={12} md={6}>
            <img className={classes.img} src={mapa} alt='Áreas de atuação' />
          </Grid>
          <Grid sm={12} md={5}>
            <Box p={3} pb={{ xs: 20, md: 3 }}>
              <AcUnitIcon className={classes.icon} />
              <Typography variant='h4' component='h3' className={classes.dark} gutterBottom={true}>
                Áreas de atuação
              </Typography>
              <Typography variant='body2' component='p' className={classes.dark2}>
                A Maia Projetos tem atuado nas quatro regiões do país. A partir dos desafios que recebemos, criamos soluções inovadoras, visando trazer o melhor retorno possível.
              </Typography>
              <Box display={{ xs: 'none', md: 'flex' }} alignItems="center" mt={20}>
                <ArrowBackIcon className={classes.icon} />
                <Typography variant='body2' component='p' className={`${classes.dark} ${classes.fontBold}`}>
                  Mapa ao lado destaca estados
                  onde há projetos.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Box>
      </Container>
    </Grid>
  )
}