import React from 'react';
import { makeStyles, Grid, Box, Typography, Container, Button } from '@material-ui/core';

//IMAGES
import bgBeneficios from '../assets/images/bgBeneficios.webp';
//ICONS
import AcUnitIcon from '@material-ui/icons/AcUnit';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';


const useStyles = makeStyles((theme) => ({
  img: {
    width: '100%',
  },
  dark: {
    color: theme.palette.common.black,
  },
  info: {
    color: theme.palette.info.main,
    marginBottom: 0,
  },
  icon: {
    fontSize: '2.2rem',
    color: theme.palette.info.main,
  },
  iconCircle: {
    fontSize: '1rem',
    marginLeft: '1rem',
    color: theme.palette.info.main,
  },
  button: {
    backgroundColor: 'secondary',
    padding: '0.75rem 1rem',
    marginTop: '3rem',
    color: 'white',
    boxShadow: 'none',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    fontWeight: 'Bold',
    textTransform: 'inherit',
  },
  bgBeneficios: {
    backgroundColor: '#ECEFF7',
    backgroundSize: 'auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom 100px left 0',
    backgroundImage: `url(${bgBeneficios})`,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'none',
    }
  },
}));

export default function Beneficios() {
  const classes = useStyles();
  return (
    <Grid className={classes.bgBeneficios}>
      <Container>
        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent='space-between' p={10} px={0}>
          <Grid item sm={12} md={3}>
            <Box p={3}>
              <AcUnitIcon className={classes.icon} />
              <Typography variant='h4' component='h3' className={classes.dark}>
                Benefícios e Vantagens
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} md={8}>
            <Box display='flex' flexWrap='wrap' justifyContent="space-around">
              <Grid item xs={12} sm={6} md={4}>
                <Box p={7}>
                  <Box display='flex' justifyContent='flex-start' alignItems='center'>
                    <Typography variant='h2' className={classes.info}>01</Typography>
                    <RadioButtonUncheckedIcon className={classes.iconCircle} />
                  </Box>
                  <Box pt={3}>
                    <Typography variant='body2' className={classes.dark}>Engenharia com foco
                      em resultado. </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box p={7}>
                  <Box display='flex' justifyContent='flex-start' alignItems='center'>
                    <Typography variant='h2' className={classes.info}>02</Typography>
                    <RadioButtonUncheckedIcon className={classes.iconCircle} />
                  </Box>
                  <Box pt={3}>
                    <Typography variant='body2' className={classes.dark}>Análise de consistência de projeto para garantir a qualidade técnica.  </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box p={7}>
                  <Box display='flex' justifyContent='flex-start' alignItems='center'>
                    <Typography variant='h2' className={classes.info}>03</Typography>
                    <RadioButtonUncheckedIcon className={classes.iconCircle} />
                  </Box>
                  <Box pt={3}>
                    <Typography variant='body2' className={classes.dark}>Elaboração de documentação técnica de contratação para eliminar pendencias de escopo. </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box p={7}>
                  <Box display='flex' justifyContent='flex-start' alignItems='center'>
                    <Typography variant='h2' className={classes.info}>04</Typography>
                    <RadioButtonUncheckedIcon className={classes.iconCircle} />
                  </Box>
                  <Box pt={3}>
                    <Typography variant='body2' className={classes.dark}>Soluções otimizadas para redução de custo.</Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box p={7}>
                  <Box display='flex' justifyContent='flex-start' alignItems='center'>
                    <Typography variant='h2' className={classes.info}>05</Typography>
                    <RadioButtonUncheckedIcon className={classes.iconCircle} />
                  </Box>
                  <Box pt={3}>
                    <Typography variant='body2' className={classes.dark}>Aplicação das melhores soluções existentes no mercado. </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Box p={7}>
                  <Box display='flex' justifyContent='center' alignItems='center'>
                    <Button className={classes.button} variant="contained" color="secondary" href='https://api.whatsapp.com/send?phone=5521996416096&text=Ol%C3%A1,%20Maia%20Projetos' target='_blank'>
                      Como podemos te ajudar?
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Container>
    </Grid>
  )
}