import React from 'react';
import { makeStyles, Container, Grid, Box, Typography, Button } from '@material-ui/core';
import AcUnitIcon from '@material-ui/icons/AcUnit';

//icons
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';


const useStyles = makeStyles((theme) => ({
  spacing: 4,
  section: {
    background: 'linear-gradient(48.62deg, #000000 25.94%, #007EC7 118.81%)',
  },
  info: {
    color: theme.palette.info.main,
  },
  dark2: {
    color: '#636360',
  },
  icon: {
    fontSize: '2.2rem',
    color: theme.palette.primary.main,
  },
  white: {
    color: 'white',
  },
  button: {
    backgroundColor: 'secondary',
    padding: '0.75rem 4rem',
    marginTop: '3rem',
    color: 'white',
    boxShadow: 'none',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    fontWeight: 'Bold',
    textTransform: 'inherit',
  },
  boxCheck: {
    border: '1px solid #007EC7',
    borderRadius: '6rem',
    padding: '.8rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '6px',
    [theme.breakpoints.down('sm')]: {
      display:'none',
    },
  },
  check: {
    color: 'white',
  }
}));

export default function NossosServicos() {
  const classes = useStyles();
  return (
    <Grid container className={classes.section} >
      <Container id="servicos">
        <Box display="flex" flexWrap="wrap" alignItems="flex-start" justifyContent='space-between' pt={20} px={{ xs: 4, md: 0 }} pb={25}>
          <Grid item md={6}>
            <Box pr={10}>
              <AcUnitIcon className={classes.icon} />
              <Box display='flex' alignItems='flex-start' justifyContent='space-between' flexWrap='wrap'>
                <Typography variant='h4' component='h3' className={classes.info}>
                  Nossos Serviços
                </Typography>
              </Box>
              <Box mt={{ xs: 5 }}>
                <Typography variant='body2' component='p'>
                  Garantimos a entrega de um projeto em conformidade com as normas técnicas, boas práticas de engenharia e melhor custo beneficio para o cliente.
                  <br />
                  <br />
                  O Engenheiro Mecânico Vinícius Maia é Especialista em ar condicionado e instalações mecânicas atuando em projetos de sistemas para conforto, industrial, salas limpas, hospitais e laboratórios. Com experiência de mais de 10 anos no mercado tem em seu currículo diversos projetos que somados tem a capacidade de mais de 40.000 TR. (Tonelada de Refrigeração)
                  <br />
                  <br />
                  Responsável pela fiscalização e gerenciamento de vários projetos e obras de grande porte no Brasil, assim como projetos de certificação LEED, Procel e projetos em plataforma BIM.
                </Typography>
              </Box>
              <Button className={classes.button} variant="contained" color="secondary" href='https://api.whatsapp.com/send?phone=5521996416096&text=Ol%C3%A1,%20Maia%20Projetos' target='_blank'>
                Como podemos te ajudar?
              </Button>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box p={{ sm: 0, md: 3 }} mt={{ xs: 10, sm: 10, md: 0 }}>
              <Box display='flex' alignItems='flex-start' justifyContent={{ sm: 'flex-start', md: 'space-between' }} p='1rem' flexWrap='wrap'>
                <Box p={10} className={classes.boxCheck}>
                  <DoneOutlineIcon className={classes.check} />
                </Box>
                <Grid sm={12} md={10}>
                  <Typography variant='h5' component='h4' className={classes.white}>
                    TAB
                  </Typography>
                  <Typography variant='body2' component='p'>
                    Serviços de TAB (Testes, Ajustes e Balanceamento) dos sistemas de ar condicionado, sendo utilizados equipamentos de última geração e calibrados para garantir a performance e parâmetros requeridos pelos projetos na instalação.
                  </Typography>
                </Grid>
              </Box>
              <Box display='flex' alignItems='flex-start' justifyContent={{ sm: 'flex-start', md: 'space-between' }} p='1rem' flexWrap='wrap'>
                <Grid className={classes.boxCheck}>
                  <DoneOutlineIcon className={classes.check} />
                </Grid>
                <Grid sm={12} md={10}>
                  <Typography variant='h5' component='h4' className={classes.white}>
                    HVAC
                  </Typography>
                  <Typography variant='body2' component='p'>
                    A Maia Projetos tem como seu principal diferencial a experiência em diversos projetos de consultoria de sistema HVAC.
                  </Typography>
                </Grid>
              </Box>
              <Box display='flex' alignItems='flex-start' justifyContent={{ sm: 'flex-start', md: 'space-between' }} p='1rem' flexWrap='wrap'>
                <Grid className={classes.boxCheck}>
                  <DoneOutlineIcon className={classes.check} />
                </Grid>
                <Grid sm={12} md={10}>
                  <Typography variant='h5' component='h4' className={classes.white}>
                    BIM
                  </Typography>
                  <Typography variant='body2' component='p'>
                    Trabalho que integra arquitetos, engenheiros e construtores (AEC) na elaboração de um modelo virtual preciso, que gera uma base de dados que contém tanto informações topológicas como os subsídios necessários para orçamento, cálculo energético e previsão de insumos e ações em todas as fases da construção.
                  </Typography>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Box >
      </Container >
    </Grid >
  )
}