import React from 'react';
import { makeStyles, Grid, Box, Typography, Container } from '@material-ui/core';

//IMAGENS
import img4 from '../assets/images/image-4.webp';
import img5 from '../assets/images/image-5.webp';
import img6 from '../assets/images/image-6.webp';
import img7 from '../assets/images/image-7.webp';
import img8 from '../assets/images/image-8.webp';
import img9 from '../assets/images/image-9.webp';
import img10 from '../assets/images/image-10.webp';
import img11 from '../assets/images/image-11.webp';
import img12 from '../assets/images/image-12.webp';
import img13 from '../assets/images/image-13.webp';
import img14 from '../assets/images/image-14.webp';
import img15 from '../assets/images/image-15.webp';
import img16 from '../assets/images/image-16.webp';
import img17 from '../assets/images/image-17.webp';
import img18 from '../assets/images/image-18.webp';
import img23 from '../assets/images/image-23.webp';
import img24 from '../assets/images/image-24.webp';
import img51 from '../assets/images/image-51.webp';
import img25 from '../assets/images/image-25.webp';
import img26 from '../assets/images/image-26.webp';
//ICONS
import AcUnitIcon from '@material-ui/icons/AcUnit';


const useStyles = makeStyles((theme) => ({
  bgColor: {
    backgroundColor: 'white',
  },
  img: {
    display: 'block',
    margin: '0 auto',
    width: '100%',
  },
  img2: {
    width:'70%',
  },
  img3: {
    width:'60%',
  },
  dark: {
    color: theme.palette.common.black,
  },
  dark2: {
    color: '#636360',
  },
  icon: {
    fontSize: '2.2rem',
    color: theme.palette.info.main,
  },
  clientes: {
    margin: '10px',
    display:'flex',
    justifyContent:'center',
    alignItems: 'center',
  },
}));

export default function Clientes() {
  const classes = useStyles();
  return (
    <Grid className={classes.bgColor}>
      <Container>
        <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent='space-between' p={10} px={0}>
          <Grid item sm={12} md={3}>
            <Box p={3}>
              <AcUnitIcon className={classes.icon} />
              <Typography variant='h4' component='h3' className={classes.dark} gutterBottom={true}>
                Nossos Clientes
              </Typography>
              <Typography variant='body2' component='p' className={classes.dark2}>
                Empresas que confiam nos projetos desenvolvidos pela Maia Projetos.
                Faça parte de grupo você também.
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={12} md={7}>
            <Box display='flex' flexWrap='wrap' justifyContent={{xs: 'center', md: 'space-between'}} px={{ xs: 4, md: 10 }} mt={{ xs: 5, md: 0 }}>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img4} alt='Rede Dor' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img5} alt='ConcreJato' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img6} alt='FioCruz' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img7} alt='International Meal Company' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img8} alt='Tetra Tech' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img9} alt='Concremat' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img10} alt='AKMX Arquitetura e Engenharia' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img11} alt='Nortec Química' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img12} alt='Licks Attorneys' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img13} alt='Rio Galeão' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img14} alt='Bradesco' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img15} alt='Custódio' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img16} alt='Labs a+' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img17} alt='Viena' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img18} alt='JP Projetos' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img23} alt='Intituto Nacional de Cardiologia' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={`${classes.img} ${classes.img2}`} src={img24} width='70%' alt='Hospital Federal dos Servidores do Estado do Rio de Janeiro' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img51} alt='Hospital Getúlio Vargas Filho' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={classes.img} src={img25} alt='INSS' />
              </Grid>
              <Grid xs={3} sm={3} md={2} lg={2} className={classes.clientes}>
                <img className={`${classes.img} ${classes.img3}`} src={img26} alt='Estácio' />
              </Grid>
            </Box>
          </Grid>
        </Box>
      </Container>
    </Grid>
  )
}