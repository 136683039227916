import React from 'react';
import Slider from "react-slick";
import { makeStyles } from '@material-ui/core';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//IMAGES
import listImg1 from '../assets/images/img1.webp';
import listImg2 from '../assets/images/img2.webp';
import listImg3 from '../assets/images/img3.webp';
import listImg4 from '../assets/images/img4.webp';
import listImg5 from '../assets/images/img5.webp';
import listImg6 from '../assets/images/img6.webp';
import listImg7 from '../assets/images/img7.webp';
import listImg8 from '../assets/images/img8.webp';
import listImg9 from '../assets/images/img9.webp';
import listImg10 from '../assets/images/img10.webp';
import listImg11 from '../assets/images/img11.webp';


const useStyles = makeStyles((theme) => ({
  img: {
    width: '100%',
  },
}));


export default function SliderProjetos() {
  const classes = useStyles();

  var settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          autoplay: true,
          autoplaySpeed: 1500,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (

    <Slider {...settings}>
      <div>
        <img className={classes.img} src={listImg1} alt='Serviços' />
      </div>
      <div>
        <img className={classes.img} src={listImg2} alt='Serviços' />
      </div>
      <div>
        <img className={classes.img} src={listImg3} alt='Serviços' />
      </div>
      <div>
        <img className={classes.img} src={listImg4} alt='Serviços' />
      </div>
      <div>
        <img className={classes.img} src={listImg5} alt='Serviços' />
      </div>
      <div>
        <img className={classes.img} src={listImg6} alt='Serviços' />
      </div>
      <div>
        <img className={classes.img} src={listImg7} alt='Serviços' />
      </div>
      <div>
        <img className={classes.img} src={listImg8} alt='Serviços' />
      </div>
      <div>
        <img className={classes.img} src={listImg9} alt='Serviços' />
      </div>
      <div>
        <img className={classes.img} src={listImg10} alt='Serviços' />
      </div>
      <div>
        <img className={classes.img} src={listImg11} alt='Serviços' />
      </div>
    </Slider>
  );
}